import {
  SIGNOUT,
  COMPANY_MANAGE_SUBSCRIPTION_PAGE,
  MANAGE_EMPLOYEES,
  PROFILE,
  SETTINGS,
  ABOUT,
  HELP_AND_FEEDBACK,
  COMPANY_PROFILE,
  DASHBOARD,
  MANAGE_STUDENTS,
  REPORTS,
  COMPANY_MANAGE_USER_PAGE,
} from './routes';
import {
  BusinessCenterOutlined,
  DashboardRounded,
  Diversity3Rounded,
  Groups2Outlined,
  InfoRounded,
  LogoutOutlined,
  SettingsRounded,
} from '@mui/icons-material';
import {
  itMapConsultantIcon,
  itMapMidCareerIcon,
  itMapStudentIcon,
} from './images';

export const APP_BAR_HEIGHT = 50;
export const APP_SIDEBAR_WIDTH = 230;
export const DRAWER_WIDTH = 230;
export const DRAWER_WIDTH_MINI = 90;
export const DEFAULT_PAGINATION_PAGE_NO = 0;
export const DEFAULT_PAGINATION_COUNT = 10;
export const DEFAULT_PAGINATION = {
  data: [],
  page: DEFAULT_PAGINATION_PAGE_NO,
  count: DEFAULT_PAGINATION_COUNT,
  total: 0,
  next: false,
  previous: false,
};
export const DEFAULT_SORTING = 'ASC';
export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please refresh and try again.';
export const DEFAULT_DELETING_MESSAGE = 'Deleting...';
export const DEFAULT_DELETED_MESSAGE = 'Successfully deleted';

export const DRAWER_MENU = [
  {
    id: 1,
    title: 'Dashboard',
    url: DASHBOARD,
    icon: DashboardRounded,
  },
  {
    id: 1,
    title: 'Manage User',
    url: COMPANY_MANAGE_USER_PAGE,
    icon: BusinessCenterOutlined,
  },
  {
    id: 1,
    title: 'Manage Subscription',
    url: COMPANY_MANAGE_SUBSCRIPTION_PAGE,
    icon: DashboardRounded,
  },
  {
    id: 2,
    title: 'Young Brain',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Manage Student',
        url: MANAGE_STUDENTS,
      },
    ],
  },
  // {
  //   id: 2,
  //   title: 'Mid Career',
  //   icon: BusinessCenterOutlined,
  //   items: [
  //     {
  //       id: 1,
  //       title: 'Manage Company',
  //       url: COMPANY_PROFILE,
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   title: 'Vendor',
  //   icon: BusinessCenterOutlined,
  //   items: [
  //     {
  //       id: 1,
  //       title: 'Manage Vendors',
  //       url: MANAGE_EMPLOYEES,
  //     },
  //   ],
  // },
  {
    id: 2,
    title: 'Consultant',
    icon: BusinessCenterOutlined,
    items: [
      {
        id: 1,
        title: 'Manage Employees',
        url: MANAGE_EMPLOYEES,
      },
    ],
  },
  {
    id: 3,
    title: 'Company Profile',
    url: COMPANY_PROFILE,
    icon: BusinessCenterOutlined,
  },
  {
    id: 4,
    title: 'Profile',
    url: PROFILE,
    icon: Diversity3Rounded,
  },
  {
    id: 5,
    title: 'Reports',
    url: REPORTS,
    icon: Diversity3Rounded,
  },
  // {
  //   id: 5,
  //   title: 'Help and Feedback',
  //   url: HELP_AND_FEEDBACK,
  //   icon: Groups2Outlined,
  // },
  // {
  //   id: 6,
  //   title: 'About',
  //   url: ABOUT,
  //   icon: InfoRounded,
  // },
  // {
  //   id: 7,
  //   title: 'Settings / 設定',
  //   url: SETTINGS,
  //   icon: SettingsRounded,
  // },
  {
    id: 8,
    title: 'Sign Out',
    url: SIGNOUT,
    icon: LogoutOutlined,
  },
];

export const tableHeaderStyles = {
  fontSize: 'sm',
  fontFamily: 'Raleway',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontWeight: 'medium',
  flex: 1,
};

export const tableTextStyles = {
  fontFamily: 'Poppins',
  fontSize: 'xs',
  lineHeight: 1,
  fontWeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 1,
};

export const ALL_ITMAP_APPS = [
  {
    title: 'IT Map Student',
    description:
      'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet',
    url: process.env.REACT_APP_STUDENT_APP,
    icon: itMapStudentIcon,
    key: 'student',
  },
  {
    title: 'IT Map Mid-Career',
    description:
      'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet',
    url: process.env.REACT_APP_MID_CAREER_APP,
    icon: itMapMidCareerIcon,
    key: 'midCareer',
  },
  {
    title: 'IT Map Consultant',
    description:
      'Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor sit amet',
    url: process.env.REACT_APP_CONSULTANT_APP,
    icon: itMapConsultantIcon,
    key: 'consultant',
  },
];
