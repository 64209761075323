export const HOME = '/company';
export const STUDENT_HOME = '/company';
export const COMPANY_MANAGE_SUBSCRIPTION_PAGE = '/company/subscriptions';
export const COMPANY_MANAGE_USER_PAGE = '/company/user';
export const CONFIRM_PAYMENT_PAGE = '/company/subscriptions/confirm-payment';
export const MANAGE_EMPLOYEES = '/company/employees';
export const MANAGE_STUDENTS = '/company/students';
export const REPORTS = '/company/reports';
export const PROFILE = '/company/profile';
export const COMPANY_PROFILE = '/company/company-profile';
export const EDIT_CORPORATE_INFORMATION = '/company/corporate-info';
export const EDIT_COMPANY_PROFILE = '/company/company-profile/edit';
export const HELP_AND_FEEDBACK = '/company/help-and-feedback';
export const ABOUT = '/company/about';
export const SETTINGS = '/company/settings';
export const DASHBOARD = '/company/dashboard';

export const APP = '/app';
export const REGISTER = '/app/register';
export const CREATE_ACCOUNT_PASSWORD = '/app/account-password';
export const FORGOT_PASSWORD = '/app/forgot-password';
export const RESET_PASSWORD = '/app/reset-password';

export const LOGIN = '/app/login';
export const SIGNOUT = '/app/signout';
export const LOGIN_CALLBACK = '/login/callback';

export const ONBOARDING = '/onboarding';
export const CREATE_YOUR_PROFILE = '/onboarding/create-profile';
export const CHOOSE_SERVICE = '/onboarding/service';
