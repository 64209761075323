import { useSnackbar } from 'notistack';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from 'utils';
import {
  createCompanyProfileApi,
  editUsersApi,
  getUserApi,
  loginUserApi,
  registerUserApi,
  updateCompanyProfileApi,
  updateUserProfileApi,
} from 'utils/constants/apis';
import { DEFAULT_ERROR_MESSAGE } from 'utils/constants/app';
import { LS_USER_COMPANY_ID, LS_USER_ID } from 'utils/constants/localStorage';
import axiosInstance from 'utils/helpers/axios';
import { useAuth } from './AuthProvider';

const UserContext = createContext({
  loading: false,
  auth: null,
  user: null,
  company: null,
  createCompanyProfile: noop,
  updateUserProfile: noop,
  updateCompanyProfile: noop,
  getUserDetails: noop,
});

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const { account } = useAuth();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);

  const updateUserProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const userId = 1;
    axiosInstance
      .put(editUsersApi(userId), payload)
      .then(({ data }) => {
        setUser(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createCompanyProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    setCompany(payload);
    axiosInstance
      .post(createCompanyProfileApi(), payload)
      .then(data => {
        setCompany(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateCompanyProfile = (payload, onSuccess = noop, onError = noop) => {
    setLoading(true);
    const companyId = 1;
    axiosInstance
      .post(updateCompanyProfileApi(companyId), payload)
      .then(({ data }) => {
        setCompany(data);
        onSuccess(data);
      })
      .catch(err => {
        onError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserDetails = (onSuccess = noop, onError = noop) => {
    setLoading(true);

    const email = account?.username;

    axiosInstance
      .get(getUserApi(email))
      .then(data => {
        setUser(data);
        setCompany(data?.company);
        onSuccess(data);
      })
      .catch(err => {
        // enqueueSnackbar(t(err.error) || t(DEFAULT_ERROR_MESSAGE), {
        //   variant: 'error',
        // });
        localStorage.removeItem(LS_USER_ID);
        localStorage.removeItem(LS_USER_COMPANY_ID);
        onError(err);
      })
      .finally(() => {
        setUser({
          id: 1,
          name: 'Ravi Sharma',
          email: 'ravi@erptohama.co.jp',
          access: {
            student: true,
            midCareer: true,
            consultant: false,
            admin: true,
          },
        });
        setCompany({
          id: 1,
          name: 'ERP Tohama',
        });
        setLoading(false);
      });
  };

  const contextValue = {
    loading,
    user,
    company,
    updateUserProfile,
    createCompanyProfile,
    updateCompanyProfile,
    getUserDetails,
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
