export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const AUTH_API_ENDPOINT = process.env.REACT_APP_AUTH_API_ENDPOINT;

export const baseEndpoint = api => API_ENDPOINT + api;
export const authBaseEndpoint = api => AUTH_API_ENDPOINT + api;

// Subcriptions Endpoints
export const getSubscriptionPlansApi = (params = '') =>
  baseEndpoint('/plans/all?') + encodeURI(params);

export const getSubscriptionActivePlanApi = (params = '') =>
  baseEndpoint('/subscribed-plan/all?') + encodeURI(params);

export const downloadSubscriptionInvoiceApi = (params = '') =>
  baseEndpoint('/subscribed-plan/all');

// Employees Endpoints
export const getEmployeesApi = (params = '') =>
  baseEndpoint('/employee/all?') + encodeURI(params);

export const addEmployeeApi = (params = '') =>
  baseEndpoint('/employee/add?') + encodeURI(params);

export const editEmployeeApi = (id = '') =>
  baseEndpoint('/employee/edit/') + id;

export const deleteEmployeeApi = (id = '') =>
  baseEndpoint('/employee/delete/') + id;

// Users Endpoint
export const getUsersApi = (params = '') =>
  baseEndpoint('/get-all-user?') + encodeURI(params);

export const addUsersApi = (params = '') =>
  baseEndpoint('/create-user?') + encodeURI(params);

export const editUsersApi = (id = '') => baseEndpoint('/edit-user/') + id;

export const deleteUsersApi = (id = '') => baseEndpoint('/delete-user/') + id;

export const getAllSubscriptionPlansApi = () =>
  baseEndpoint(
    '/subscriptionplan/getAll?page=0&count=10&sort=ASC&field=subscriptionplanid'
  );

export const createSubscriptionPlanApi = () =>
  baseEndpoint('/subscriptionplan/createsubscriptionplan');

export const getAllApplicationsApi = () =>
  baseEndpoint('/subscriptionplan/getallapplications');

export const jobsApi = () => baseEndpoint('/companyData/jobs');

// Auth Endpoints
export const loginUserApi = () => authBaseEndpoint('/auth/signin');
export const registerUserApi = () => authBaseEndpoint('/auth/signup');
export const getUserApi = id => authBaseEndpoint(`/get-user/${id}`);

// Onboarding Apis
export const updateUserProfileApi = () =>
  baseEndpoint('/admin/create-user-profile');

export const createCompanyProfileApi = () =>
  baseEndpoint('/create-company-profile');
export const updateCompanyProfileApi = id =>
  baseEndpoint(`/update-company-profile/${id}`);
