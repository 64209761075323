import paymentMasterIcon from 'assets/images/master.png';
import paymentVisaIcon from 'assets/images/visa.png';
import paymentStripeIcon from 'assets/images/stripe.png';
import paymentPaypalIcon from 'assets/images/paypal.png';
import itMapStudent from 'assets/images/it-map-student.png';
import itMapMidCareer from 'assets/images/it-map-mid-career.png';
import itMapConsultant from 'assets/images/it-map-consultant.png';

export const FALLBACK_IMAGE = 'https://bit.ly/broken-link';
export const LOGO = '/logo.svg';
export const DEFAULT_PROFILE_IMAGE = '/user-default-icon.png';
export const PROFILE_BANNER_IMAGE = '/banner-pattern.jpeg';
export const SERVICE_PAGE_BG = '/servicePageBg.svg';

// Payment icons
export const PAYMENT_STRIPE_ICON = paymentStripeIcon;
export const PAYMENT_VISA_ICON = paymentVisaIcon;
export const PAYMENT_MASTER_ICON = paymentMasterIcon;
export const PAYMENT_PAYPAL_ICON = paymentPaypalIcon;

export const itMapStudentIcon = itMapStudent;
export const itMapMidCareerIcon = itMapMidCareer;
export const itMapConsultantIcon = itMapConsultant;
