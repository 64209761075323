import { LOGIN_CALLBACK, SIGNOUT } from './constants/routes';

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
    redirectUri: LOGIN_CALLBACK,
    postLogoutRedirectUri: SIGNOUT,
    // clientSecret: CLIENT_SECRET,
  },
  // cache: {
  //   cacheLocation: 'sessionStorage', // You can customize this based on your needs
  //   storeAuthStateInCookie: false,
  // },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
